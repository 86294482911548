import React, { useContext, useState } from "react"

import { FormField, createFormContext, Form, SubmitButton, renderFormInputs } from "../../component/Form"
import ServiceProvider from "../../service/ServiceProvider"
import { AppContext, useAppContext } from "../../App"
import { useAuthenticatedNavigator, AuthenticatedNavigatorContext, Page } from "../../Authenticated"
import { DataServiceContext } from "../../service/data/DataService"
import { UserAccessData } from "../../model/SiteUserAccess"
import {
    createFormFields as createSiteFormFields,
    FormValues as BaseSiteFormValues,
    RenderedFields as SiteRenderedFields,
    SiteDetailFormFragment
} from "../site/component/SiteDetailFormFragment"
import {
    createFormFields as createUserFormFields,
    BaseFormValues as UserFormValues,
    RenderedFields as UserRenderedFields,
    FormState as UserFormState,
    getDefaultFormState as getDefaultUserFormState,
    SiteUserFormFragment
} from "../site/component/SiteUserFormFragment"
import { ThingSiteData, ThingTypes } from "../../model/Thing"
import BackButton from "../../component/BackButton"

type RenderedFields = SiteRenderedFields & UserRenderedFields
type SiteFormValues = BaseSiteFormValues & { users: UserFormValues[] }
type FormValues = SiteFormValues & { submit?: string }

const ThingRegisterFormContext = createFormContext<FormValues>()
const siteFields: FormField<FormValues>[] = createSiteFormFields<FormValues>(false)

function submit(appContext: AppContext, navigator: AuthenticatedNavigatorContext, data: SiteFormValues, props: Props) {
    appContext.setLoading = true
    ServiceProvider.dataService
        .createThing(new DataServiceContext(appContext.session), {
            id: data.thing.id ?? props.thing?.id,
            serialNumber: data.thing.serialNumber ?? props.thing?.serialNumber,
            macAddress: data.thing.macAddress ?? props.thing?.macAddress,
            type: (data.thing.type as ThingTypes | undefined) ?? props.thing?.type,
            site: {
                name: data.site.name,
                address: data.site.address,
                city: data.site.city,
                postCode: data.site.postCode,
                state: data.site.state ?? props.thing?.state,
                country: data.site.country ?? props.thing?.country,
                imageUri: data.site.imageUri,
                access: data.users
                    .filter((item) => item.userId?.length > 0)
                    .map(
                        (user) =>
                            ({
                                user: user.userId.indexOf("@") >= 0 ? { email: user.userId } : { phone: user.userId },
                                contractRenewalNotice: user.contractRenewalNotice,
                                receiveErrors: user.receiveErrors
                            }) as UserAccessData
                    )
            }
        })
        .then(
            (thing) => {
                appContext.setLoading = false
                appContext.modal.add({ title: "Registration", content: "The site has been successfully registered" })
                if (thing.site) navigator.navigateTo(Page.SiteDashboard, { siteId: thing.site })
                else navigator.navigateTo(Page.Dashboard)
            },
            (error) => {
                appContext.setLoading = false
                appContext.processError(error, "Registration Error", "Sorry, the site could not be registered")
            }
        )
}

export interface Props {
    thing: ThingSiteData
}

function ThingRegisterFormContent({ props }: { props: Props }) {
    const navigator = useAuthenticatedNavigator()
    const formContext = useContext(ThingRegisterFormContext)
    const content = { userAccess: props?.thing.users }
    const [userState, setUserState] = useState<UserFormState>(() => getDefaultUserFormState(content))
    const renderedFields = renderFormInputs(formContext, [
        ...siteFields,
        ...createUserFormFields<FormValues>(
            undefined,
            "users",
            userState,
            setUserState,
            formContext as any // FIXME: Type buster
        )
    ]) as RenderedFields

    return (
        <>
            <div className="columns">
                <SiteDetailFormFragment formController={formContext as any} renderedFields={renderedFields} />
                <SiteUserFormFragment
                    renderedFields={renderedFields}
                    formController={formContext as any}
                    formState={userState}
                    setFormState={setUserState}
                />
            </div>
            <div className="button-panel">
                <BackButton />
                <SubmitButton
                    context={ThingRegisterFormContext}
                    wrapped={false}
                    submit={{ name: "submit", title: "Save" }}
                />
            </div>
        </>
    )
}

export default function Register(props: Props) {
    const formName = "thing-register"
    const appContext = useAppContext()
    const navigator = useAuthenticatedNavigator()
    const onsubmit = (data: FormValues) => submit(appContext, navigator, data, props)
    const transformedData = props.thing.users.map((obj) => {
        const newObj = {
            ...obj,
            userId: obj.user_id,
            contractRenewalNotice: obj.contract_notification,
            receiveErrors: obj.error_notification
        }
        // Delete the original keys after creating the new object
        delete newObj.user_id
        delete newObj.contract_notification
        delete newObj.error_notification
        return newObj
    })

    const defaultValues = { thing: props.thing, site: { ...props.thing }, users: transformedData }
    return (
        <div id={formName + "-form-container"} className="form-container content-site-editor">
            <Form context={ThingRegisterFormContext} name={formName} onSubmit={onsubmit} defaultValues={defaultValues}>
                <ThingRegisterFormContent props={props} />
            </Form>
        </div>
    )
}
