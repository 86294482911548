import React from "react"
import { FieldOptions, FormController, FormField } from "../../../component/Form"
import { FieldValues } from "react-hook-form/dist/types"
import { FieldPath } from "react-hook-form/dist/types/path"
import { CheckKeyConstraint } from "react-hook-form/dist/types/path/common"
import Site, { PlaceholderSiteImage, SiteData } from "../../../model/Site"
import Thing from "../../../model/Thing"
import ServiceProvider from "../../../service/ServiceProvider"
import { DataServiceContext } from "../../../service/data/DataService"
import { DateTime } from "luxon"

type DestinationPath<TFieldValues, TTarget> = CheckKeyConstraint<TFieldValues, FieldPath<TFieldValues>, TTarget>

export type FormValues = {
    thing: ThingFormValues
    site: SiteFormValues
}

export type ThingFormValues = {
    id: string
    serialNumber: string
    macAddress: string
    type: string
}

export type SiteFormValues = {
    id?: string
    agreementEndDate?: string
    name: string
    address: string
    city: string
    postCode: string
    state: string
    country: string
    imageUri?: string
}

export type RenderedFields = {
    "thing.id": React.JSX.Element
    "thing.serialNumber": React.JSX.Element
    "thing.macAddress": React.JSX.Element
    "thing.type-individual-unit": React.JSX.Element
    "thing.type-system-manifold": React.JSX.Element
    "site.id": React.JSX.Element
    "site.agreementEndDate": React.JSX.Element
    "site.name": React.JSX.Element
    "site.address": React.JSX.Element
    "site.city": React.JSX.Element
    "site.postCode": React.JSX.Element
    "site.state": React.JSX.Element
    "site.country": React.JSX.Element
    "site.imageUri": React.JSX.Element
}

export type Content = {
    site: SiteData
    thing: Thing
}

export function createFormFields<TFieldValues extends FieldValues = FormValues>(
    update: boolean
): FormField<TFieldValues>[] {
    const undef = undefined
    const options = { disabled: true }

    return [
        createFormField("thing.id", undef, undef, options),
        createFormField("thing.serialNumber", undef, undef, options),
        createFormField("thing.macAddress", undef, undef, options),
        createFormField("thing.type", { text: "Individual Unit", value: "individual-unit" }, undef, options),
        createFormField("thing.type", { text: "System Manifold", value: "system-manifold" }, undef, options),
        createFormField("site.id", undef, undef, options),
        createFormField("site.agreementEndDate", undef, undef, options),
        createFormField("site.name"),
        createFormField("site.city"),
        createFormField("site.postCode"),
        createFormField("site.address"),
        // createFormField("site.state", undef, undef, update ? options : undef),
        // createFormField("site.country", undef, undef, update ? options : undef)
        createFormField("site.state", undef, undef, options),
        createFormField("site.country", undef, undef, options)
    ]
}

export function createFormField<
    TFieldValues extends FieldValues = FormValues,
    TDestinationPath extends DestinationPath<TFieldValues, FormValues> = DestinationPath<TFieldValues, FormValues>
>(
    field: FieldPath<FormValues>,
    subField?: { text: string; value: string },
    prefix?: TDestinationPath,
    options?: { disabled?: boolean }
): FormField<TFieldValues> {
    const name = (prefix !== undefined ? `${prefix}.${field}` : field) as FieldPath<TFieldValues>

    switch (field) {
        case "thing.id":
            return { name, label: "Thing Id", options: { ...FieldOptions.Required, ...options } }
        case "thing.serialNumber":
            return { name, label: "Serial Number", options: { ...FieldOptions.Required, ...options } }
        case "thing.macAddress":
            return { name, label: "MAC Address", options: { ...FieldOptions.Required, ...options } }
        case "thing.type":
            return {
                key: name + "-" + subField.value,
                name,
                label: subField.text,
                input: { element: "input", props: { type: "radio", value: subField.value } },
                options: { ...FieldOptions.Required, ...options }
            }
        case "site.id":
            return { name, label: "Site Id", options: { ...options } }
        case "site.agreementEndDate":
            return { name, label: "Agreement End Date", options: { ...options } }
        case "site.name":
            return { name, label: "Site Name", options: { ...FieldOptions.Required, ...options } }
        case "site.city":
            return { name, label: "City", options: { ...FieldOptions.Required, ...options } }
        case "site.postCode":
            return { name, label: "Postcode", options: { ...FieldOptions.Required, ...options } }
        case "site.address":
            return { name, label: "Address", options: { ...FieldOptions.Required, ...options } }
        case "site.state":
            return {
                name,
                label: "State",
                input: {
                    element: "select",
                    options: [
                        ["", "Other / Not Applicable"],
                        ["ACT", "Australian Capital Territory"],
                        ["NSW", "New South Wales"],
                        ["NT", "Northern Territory"],
                        ["QLD", "Queensland"],
                        ["SA", "South Australia"],
                        ["TAS", "Tasmania"],
                        ["VIC", "Victoria"],
                        ["WA", "Western Australia"]
                    ]
                },
                options: {
                    ...options,
                    validate: (value, values) => {
                        switch (values.site.country) {
                            case "AU": {
                                if (value === "") return " is required for Australia"
                                else return true
                            }
                            case "NZ": {
                                if (value === "") return true
                                else return " is not valid for New Zealand"
                            }
                            default: {
                                return " requires the country field to have a value"
                            }
                        }
                    }
                }
            }
        case "site.country":
            return {
                name,
                label: "Country",
                input: {
                    element: "select",
                    options: [
                        ["AU", "Australia"],
                        ["NZ", "New Zealand"]
                    ]
                },
                options: { ...FieldOptions.Required, ...options }
            }
        case "site.imageUri":
            return { name, input: { element: "input", props: { type: "hidden" } } }
    }
}

export function loadContent(context: DataServiceContext, siteId: string): Promise<Content> {
    const dataService = ServiceProvider.dataService

    return Promise.all([dataService.getSite(context, siteId), dataService.getSiteThings(context, siteId)]).then(
        ([site, things]: [Site, Thing[]]) => ({
            site: site,
            thing: things[0]
        }),
        (error) =>
            this.appContext.processError(error, "Site Information", "Sorry, we could not retrieve site information")
    )
}

export function convertContentToFormValues(content: Content): FormValues {
    return {
        thing: content.thing,
        site: { ...content.site, agreementEndDate: content.site.agreementEndDate?.toLocaleString(DateTime.DATE_MED) }
    }
}

export function SiteDetailFormFragment({
    renderedFields,
    formController
}: {
    renderedFields: RenderedFields
    formController: FormController<FormValues>
}) {
    const imageFileRef = React.useRef<HTMLInputElement>()
    const [image, setImage] = React.useState(() => formController.getValue(`site.imageUri`))
    const agreementEndDate = formController.getValue(`site.agreementEndDate`)

    const openImageDialog = () => {
        imageFileRef.current?.click()
    }

    const uploadImage = () => {
        const file = imageFileRef.current?.files[0]

        if (file.size > 2 * 1024 * 1024 * 1024) {
            alert("Image must not be greater than 2MB in size.")
            return
        } else if (file.type !== "image/jpeg") {
            alert("Image must be a JPEG file.")
            return
        }

        const reader = new FileReader()

        reader.addEventListener("load", () => {
            const image = reader.result as string
            formController.setValue(`site.imageUri`, reader.result as string)
            setImage(image)
        })

        reader.addEventListener("error", () => {
            alert("Could not read image")
        })

        reader.readAsDataURL(file)
    }

    const deleteImage = () => {
        formController.setValue(`site.imageUri`, undefined)
        setImage(undefined)
    }

    return (
        <section className="column section-site">
            <h3>Site Details</h3>
            <div className="box">
                <fieldset className="section-site-details">
                    <legend>System Details</legend>
                    {renderedFields[`thing.id`]}
                    {renderedFields[`thing.serialNumber`]}
                    {renderedFields[`thing.macAddress`]}
                    <div className="form-item-group">
                        {renderedFields[`thing.type-individual-unit`]}
                        {renderedFields[`thing.type-system-manifold`]}
                    </div>
                </fieldset>
                <fieldset className="section-site-location">
                    <legend>Site Location</legend>
                    {image === undefined || image === null || image === "" ? (
                        <div className="image">
                            <img src={PlaceholderSiteImage} alt="Placeholder image for site" />
                            <button
                                type="button"
                                className="primary"
                                children="Upload Picture"
                                onClick={openImageDialog}
                            />
                        </div>
                    ) : (
                        <div className="image">
                            <img src={image} alt="Uploaded picture of site location" />
                            <button
                                type="button"
                                className="primary"
                                children="Replace Picture"
                                onClick={openImageDialog}
                            />
                        </div>
                    )}
                    <div className="address">
                        {renderedFields[`site.id`]}
                        {agreementEndDate && renderedFields[`site.agreementEndDate`]}
                        {renderedFields[`site.name`]}
                        {renderedFields[`site.address`]}
                        {renderedFields[`site.city`]}
                        {renderedFields[`site.postCode`]}
                        {renderedFields[`site.country`]}
                        {renderedFields[`site.state`]}
                    </div>
                    <input
                        ref={imageFileRef}
                        type="file"
                        accept=".jpg,.jpeg"
                        onChange={uploadImage}
                        style={{ display: "none" }}
                    />
                    {renderedFields[`site.imageUri`]}
                </fieldset>
            </div>
        </section>
    )
}
