// noinspection HtmlUnknownAnchorTarget

import React, { SyntheticEvent } from "react"
import { AppContext, useAppContext } from "../../App"
import { DataServiceContext } from "../../service/data/DataService"
import ServiceProvider from "../../service/ServiceProvider"
import { AuthenticatedNavigatorContext, Page, useAuthenticatedNavigator } from "../../Authenticated"
import Site, { DefaultSiteImage } from "../../model/Site"
import SiteErrorList from "./component/SiteErrorList"
import OrganisationalChart from "./component/OrganisationalChart"
import SiteReport from "./component/SiteReport"
import Report from "../../model/Report"
import { ActionButton } from "../../component/ActionButton"
import { SiteShareDialog } from "./component/SiteShare"
import FullScreenLoader from "../../component/Loading"
import BackButton from "../../component/BackButton"

export type SiteDashboardProps = { siteId: string }

class SiteDashboardController {
    private _appContext: AppContext
    private _navigator: AuthenticatedNavigatorContext
    private _dataServiceContext: DataServiceContext
    private _siteId: string
    private _site: Site
    private _setSite: React.Dispatch<React.SetStateAction<Site>>
    private _reports: Report[]
    private _siteState: any

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    static use(props: SiteDashboardProps) {
        // Collect state references

        // Setup instance
        const ref = React.useRef<SiteDashboardController>()
        const appContext = useAppContext()
        const navigator = useAuthenticatedNavigator()
        const [, setSite] = React.useState<Site>()

        // Check to see if we need to initialise a new context
        let context = ref.current
        let initialise = false

        if (!context) {
            initialise = true
            context = new SiteDashboardController()
            context._appContext = appContext
            context._navigator = navigator
            context._dataServiceContext = new DataServiceContext(appContext.session)
            context._setSite = setSite

            ref.current = context
        } else if (context._siteId !== props.siteId) {
            initialise = true
        }

        if (initialise) context.initialise(props.siteId)

        // Return instance
        return context
    }

    protected initialise(siteId: string) {
        this._siteId = siteId
        this._site = undefined
        this._siteState = undefined

        ServiceProvider.dataService.getSite(this._dataServiceContext, siteId).then(
            (site) => {
                this._site = site
                this._siteState = site?.state
                this._setSite(site)
            },
            (error) => this._appContext.processError(error, "Site Error", "Could not fetch site information")
        )

        this._reports = undefined
        ServiceProvider.dataService.getSiteReports(this._dataServiceContext, siteId).then(
            (result) => {
                this._reports = result
            },
            (error) =>
                this._appContext.processError(
                    error,
                    "Site Report",
                    "Sorry, we could not receive a list of site reports"
                )
        )
    }

    protected load(siteId: string) {
        this._setSite(undefined)
        this.initialise(siteId)
    }

    public get siteId(): string {
        return this._siteId
    }

    public set siteId(siteId: string) {
        this._siteId = siteId
    }

    public get site(): Site | undefined {
        return this._site
    }

    public get reports() {
        return this._reports
    }

    public readonly navigateToEdit = () => {
        const site = this.site
        this._navigator.navigateTo(Page.SiteEditor, { siteId: site.id, siteName: site.name })
    }

    public readonly showShareSiteDialog = () => {
        const site = this.site
        const modal = this._appContext.modal
        modal.add(new SiteShareDialog(this._appContext, modal, [site.id]))
    }

    public readonly onImageError = (e: SyntheticEvent<HTMLImageElement>) => {
        const el = e.currentTarget

        if (el.src === this.site.imageUri && el.src !== DefaultSiteImage) {
            el.src = DefaultSiteImage
        }
    }
}

export default function SiteDashboard(props: SiteDashboardProps) {
    const context = SiteDashboardController.use(props)
    const navigator = useAuthenticatedNavigator()
    const site = context.site
    const reports = context.reports
    if (!site && !reports) return <FullScreenLoader />

    const address = `${site.address}${site.city ? ", " + site.city : ""}${site.state ? ", " + site.state : ""}${
        site.country ? ", " + site.country : ""
    }`

    return (
        <>
            <div className="back-btn">
                <BackButton />
            </div>
            <section className="section-site-info">
                <img src={site.imageUri ?? DefaultSiteImage} alt="" onError={context.onImageError} />
                <h3>{site.name}</h3>
                <address>{address}</address>
                <div className="button-panel">
                    <ActionButton key="edit" type="primary" text="Edit" icon="edit" onClick={context.navigateToEdit} />
                    <ActionButton
                        key="share"
                        type="primary"
                        text="Share"
                        icon="share"
                        onClick={context.showShareSiteDialog}
                    />
                </div>
            </section>
            <section className="section-jump-bar">
                <a href="#device-information">Device Information</a>
                <a href="#statistics">Reporting</a>
                <a href="#errors">Errors</a>
            </section>
            <section className="section-device-information">
                <OrganisationalChart siteId={site.id} />
            </section>
            <section className="section-report">
                <h3 id="statistics">Reporting</h3>
                <SiteReport
                    key="report-1"
                    report="avg_max_fuel_usage"
                    siteId={site.id}
                    allReports={reports}
                    state={site.state}
                    country={site.country}
                />
                <SiteReport
                    key="report-2"
                    defaultReport="gas_consumption_rate_heater"
                    siteId={site.id}
                    allReports={reports}
                    state={site.state}
                    country={site.country}
                />
                <SiteReport
                    key="report-3"
                    defaultReport="total_combustion_time"
                    siteId={site.id}
                    allReports={reports}
                    state={site.state}
                    country={site.country}
                />
            </section>
            <section className="section-errors">
                <SiteErrorList siteId={site.id} />
            </section>
            <div className="back-btn">
                <BackButton />
            </div>
        </>
    )
}
