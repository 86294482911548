import React, {
    BaseSyntheticEvent,
    ClassAttributes,
    InputHTMLAttributes,
    ReactNode,
    SelectHTMLAttributes,
    useContext
} from "react"
import { DefaultValues, FieldError, FieldValues, Path, UseFormReturn } from "react-hook-form/dist/types"
import { UseFormProps } from "react-hook-form/dist/types/form"
import { useForm } from "react-hook-form"
import { FieldPath, FieldPathValue, FieldPathValues } from "react-hook-form/dist/types/path"
import { RegisterOptions } from "react-hook-form/dist/types/validator"
import { FieldErrors } from "react-hook-form/dist/types/errors"

export const FieldOptions = {
    RequiredEmail: {
        required: true,
        maxLength: 254,
        pattern: /^\w+[\w.+-]*@[\w-]+([.][a-zA-Z]+|[.][\w-]+[.][a-zA-Z]{2,})$/
    },
    RequiredPassword: {
        required: true,
        validate: (value) => {
            if (
                !/(?=^.{8,}$)(?=.*\d)(?=.*[~`!@#$%^&*()_\-+=[\]{}\\|:;"',./<>? ]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                    value
                )
            ) {
                if (value.length < 8) return " must be at least 8 characters"
                else if (!/[A-Z]/.test(value)) return " must contain at least one uppercase character"
                else if (!/[a-z]/.test(value)) return " must contain at least one lowercase character"
                else if (!/\d/.test(value)) return " must contain at least one numeric character"
                else if (!/[~`!@#$%^&*()_\-+=[\]{}\\|:;"',./<>?]/.test(value))
                    return " must contain at least one special characters"
                else return " must contain a valid value"
            }

            return true
        }
    },
    RequiredPassword2: { required: true },
    Required: { required: true }
}

type FormFieldInput =
    | {
          element: "input"
          props?: InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement>
      }
    | {
          element: "select"
          options: [value: string, text: string][]
          props?: SelectHTMLAttributes<HTMLSelectElement> & ClassAttributes<HTMLSelectElement>
      }

function getErrorMessage<TFieldValues extends FieldValues = FieldValues>(
    field: FormField<TFieldValues>,
    error: FieldError
) {
    if (error.message && error.message !== "") return (field.title ?? field.label) + error.message
    else if (error.type === "required") return (field.title ?? field.label) + " is required"
    else return (field.title ?? field.label) + " is not valid"
}

export interface FormField<
    TFieldValues extends FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    key?: string
    name: FieldPath<TFieldValues>
    title?: string
    label?: string | ReactNode
    input?: FormFieldInput
    relatedPasswordField?: FieldPath<TFieldValues>
    options?: RegisterOptions<TFieldValues, TFieldName>
}

export function createFormContext<TFieldValues extends FieldValues = FieldValues>(): React.Context<
    FormController<TFieldValues>
> {
    return React.createContext<FormController<TFieldValues>>(undefined)
}

export function useInputRenderer<TFieldValues extends FieldValues = FieldValues>(
    props: FormController<TFieldValues>
): (input: FormField<TFieldValues>) => ReactNode {
    const defaultTextInput: FormFieldInput = { element: "input", props: { type: "text" } }
    const {
        name,
        implementation: { register, getValues, getFieldState, formState }
    } = props

    return (field: FormField<TFieldValues>) => {
        const input: FormFieldInput = field.input ?? defaultTextInput
        const { error } = getFieldState(field.name, formState)
        const errorMessage = error ? getErrorMessage(field, error) : null
        let options = field.options

        if (field.relatedPasswordField) {
            const passwordField = field.relatedPasswordField
            options = {
                ...options,
                validate: (value) => {
                    const firstPasswordValue = getValues(passwordField)
                    return firstPasswordValue === value
                }
            }
        }

        const containerId = name + "-form-container-" + (field.key ?? field.name)
        const inputId = name + "-form-input-" + (field.key ?? field.name)
        const containerClass =
            "form-input form-input-type-" +
            input.element +
            (input.element === "input" ? "-" + (input.props?.type ?? "text") : "") +
            (errorMessage ? " form-input-error" : "")

        const labelNode = field.label && <label key="label" htmlFor={inputId} children={field.label} />

        const inputNode =
            input.element === "input" ? (
                <input
                    key="input"
                    placeholder={field.label && typeof field.label === "string" ? field.label : undefined}
                    {...input.props}
                    id={inputId}
                    {...register(field.name, options)}
                />
            ) : input.element === "select" ? (
                <select
                    key="input"
                    {...input.props}
                    id={inputId}
                    children={input.options.map((option) => (
                        <option key={option[0]} value={option[0]}>
                            {option[1]}
                        </option>
                    ))}
                    {...register(field.name, options)}
                />
            ) : null

        const errorNode = errorMessage && (
            <div key="error" className="form-input-error-message" children={errorMessage} />
        )

        const inputFirst =
            input.element === "input" && (input.props?.type === "checkbox" || input.props?.type === "radio")
        const nodes = inputFirst ? [inputNode, labelNode, errorNode] : [labelNode, inputNode, errorNode]

        return <div key={field.key ?? field.name} id={containerId} className={containerClass} children={nodes} />
    }
}

export function renderFormInputs<TFieldValues extends FieldValues = FieldValues>(
    props: FormController<TFieldValues>,
    fields: FormField<TFieldValues>[]
): { [TKey in FieldPath<TFieldValues>]?: ReactNode } {
    const result: { [TKey in FieldPath<TFieldValues>]?: ReactNode } = {}
    const renderer = useInputRenderer(props)
    const len = fields.length

    for (let i = 0; i < len; ++i) {
        const field = fields[i]
        result[field.key ?? field.name] = renderer(field)
    }

    return result
}

type InputFormProps<TFieldValues extends FieldValues = FieldValues> = {
    context: React.Context<FormController<TFieldValues>>
    fields: FormField<TFieldValues>[]
    tmp?: number
}

export function InputForm<TFieldValues extends FieldValues = FieldValues>(
    props: InputFormProps<TFieldValues>
): JSX.Element {
    const form = useContext(props.context)

    return (
        <div id={form.name + "-form-inputs"} className="form-inputs">
            {props.fields.map(useInputRenderer(form))}
        </div>
    )
}

type SubmitButtonParams<TFieldValues extends FieldValues = FieldValues> = {
    name: Path<TFieldValues>
    title?: string
    extra?: JSX.Element
}

export interface SubmitButtonProps<TFieldValues extends FieldValues = FieldValues> {
    context: React.Context<FormController<TFieldValues>>
    wrapped?: boolean
    submit?: SubmitButtonParams<TFieldValues>
}

export function SubmitButton<TFieldValues extends FieldValues = FieldValues>(props: SubmitButtonProps<TFieldValues>) {
    const submit = props.submit
    const form = useContext(props.context)
    const wrapped = props.wrapped ?? true
    const register = form.implementation.register
    const submitTitle = submit?.title ?? "Submit"

    if (submit === undefined) return null
    let result: ReactNode = <button {...register(submit.name)} key="submit" type="submit" children={submitTitle} />

    if (wrapped) {
        result = (
            <p id={form.name + "-form-submit"} className="form-input form-submit">
                {result}
                {submit.extra}
            </p>
        )
    }

    return result
}

export class FormController<TFieldValues extends FieldValues = FieldValues> {
    public readonly implementation: UseFormReturn<TFieldValues>
    public readonly submitHandler: (e?: BaseSyntheticEvent) => Promise<void>
    public onSubmit: (data: TFieldValues, event?: React.BaseSyntheticEvent) => void
    public onError: (data: FieldErrors<TFieldValues>, event?: React.BaseSyntheticEvent) => void
    private props: FormPropsBase<TFieldValues>
    private lastSubmit: boolean

    private constructor(props: FormPropsBase<TFieldValues>, implementation: UseFormReturn<TFieldValues>) {
        this.props = props
        this.submitHandler = implementation.handleSubmit(this.submit.bind(this), this.error.bind(this))
        this.implementation = implementation
    }

    public async triggerSubmit(): Promise<boolean> {
        await this.submitHandler()
        return this.lastSubmit
    }

    public get name() {
        return this.props.name
    }

    public setValue<TFieldName extends FieldPath<TFieldValues>>(
        name: TFieldName,
        // value: FieldPathValue<TFieldValues, TFieldName>
        value: FieldValues[TFieldName] | undefined
    ) {
        this.implementation.setValue(name, value)
    }

    // noinspection JSUnusedGlobalSymbols
    public getValue<TFieldName extends FieldPath<TFieldValues>>(
        name: TFieldName
    ): FieldPathValue<TFieldValues, TFieldName> {
        return this.implementation.getValues(name)
    }

    // noinspection JSUnusedGlobalSymbols
    public getMultipleValues<TFieldNames extends FieldPath<TFieldValues>[]>(
        names: readonly [...TFieldNames]
    ): [...FieldPathValues<TFieldValues, TFieldNames>] {
        return this.implementation.getValues(names)
    }

    public getValues(): TFieldValues {
        return this.implementation.getValues()
    }

    public static use<TFieldValues>(props: FormPropsBase<TFieldValues>): FormController<TFieldValues> {
        const formProps: UseFormProps<TFieldValues> = { mode: "onBlur", defaultValues: props.defaultValues }
        const implementation = useForm<TFieldValues>(formProps)
        const ref = React.useRef<FormController<TFieldValues>>()
        let context = ref.current

        if (context && !context.error) {
            context.props = props
        } else {
            context = new FormController(props, implementation)
            ref.current = context
        }

        return context
    }

    public readonly submit = (data: TFieldValues, event?: React.BaseSyntheticEvent) => {
        this.lastSubmit = true
        this.props.onSubmit?.(data, event)
        this.onSubmit?.(data, event)
    }

    public readonly error = (errors: FieldErrors<TFieldValues>, event?: React.BaseSyntheticEvent) => {
        this.lastSubmit = false
        this.props.onError?.(errors, event)
        this.onError?.(errors, event)
    }
}

interface FormPropsBase<TFieldValues extends FieldValues = FieldValues> {
    name: string
    context: React.Context<FormController<TFieldValues>>
    defaultValues?: DefaultValues<TFieldValues>
    onSubmit?: (data: TFieldValues, event?: React.BaseSyntheticEvent) => void
    onError?: (data: FieldErrors<TFieldValues>, event?: React.BaseSyntheticEvent) => void
}

export interface ExternalFormProps<TFieldValues extends FieldValues = FieldValues> {
    controller: FormController<TFieldValues>
    children: ReactNode
}

export function ExternalForm<TFieldValues extends FieldValues = FieldValues>(props: ExternalFormProps<TFieldValues>) {
    const controller = props.controller
    const children = props.children

    return (
        <section id={controller.name + "-form"} className="form">
            <form onSubmit={controller.submitHandler} noValidate={true} children={children} />
        </section>
    )
}

export interface FormProps<TFieldValues extends FieldValues = FieldValues> extends FormPropsBase<TFieldValues> {
    controller?: React.MutableRefObject<FormController<TFieldValues>>
    children: ReactNode
}

export function Form<TFieldValues extends FieldValues = FieldValues>(props: FormProps<TFieldValues>) {
    const controller = FormController.use(props)
    const FormContext = props.context
    if (props.controller) props.controller.current = controller

    return (
        <FormContext.Provider value={controller}>
            <ExternalForm controller={controller} children={props.children} />
        </FormContext.Provider>
    )
}

export interface BasicFormProps<TFieldValues extends FieldValues = FieldValues> extends FormPropsBase<TFieldValues> {
    fields: FormField<TFieldValues>[]
    submit?: SubmitButtonParams<TFieldValues>
}

export function BasicForm<TFieldValues extends FieldValues = FieldValues>(props: BasicFormProps<TFieldValues>) {
    return (
        <Form context={props.context} name={props.name} onSubmit={props.onSubmit}>
            <InputForm key="input" context={props.context} fields={props.fields} />
            <SubmitButton key="submit" context={props.context} submit={props.submit} />
        </Form>
    )
}
