import React, { useContext, useState } from "react"

import { FormField, createFormContext, Form, SubmitButton, renderFormInputs } from "../../component/Form"
import ServiceProvider from "../../service/ServiceProvider"
import { useAuthenticatedNavigator, AuthenticatedNavigatorContext, Page } from "../../Authenticated"
import { AppContext, useAppContext } from "../../App"
import { SiteData } from "../../model/Site"
import DataService, { DataServiceContext } from "../../service/data/DataService"
import { UserAccessData } from "../../model/SiteUserAccess"
import {
    createFormFields as createSiteFormFields,
    loadContent as loadSiteContent,
    convertContentToFormValues as convertSiteContentToSiteFormValues,
    Content as SiteContent,
    FormValues as BaseSiteFormValues,
    RenderedFields as SiteRenderedFields,
    SiteDetailFormFragment
} from "./component/SiteDetailFormFragment"
import {
    createFormFields as createUserFormFields,
    loadContent as loadUserContent,
    convertContentToFormValues as convertUserContentToUserFormValues,
    Content as UserContent,
    FormValues as UserFormValues,
    RenderedFields as UserRenderedFields,
    SiteUserFormFragment,
    FormState as UserFormState,
    getDefaultFormState as getDefaultUserFormState
} from "./component/SiteUserFormFragment"
import FullScreenLoader from "../../component/Loading"
import BackButton from "../../component/BackButton"

type RenderedFields = SiteRenderedFields & UserRenderedFields
type SiteFormValues = BaseSiteFormValues & UserFormValues
type FormValues = SiteFormValues & { submit?: string }

const SiteEditorFormContext = createFormContext<FormValues>()
const siteFields: FormField<FormValues>[] = createSiteFormFields<FormValues>(true)

function submit(appContext: AppContext, navigator: AuthenticatedNavigatorContext, content: Content, data: FormValues) {
    const siteId = content.siteId
    const context: DataServiceContext = new DataServiceContext(appContext.session)
    const dataService: DataService = ServiceProvider.dataService
    const { name, address, city, postCode, state, country, imageUri } = data.site
    const site: SiteData = {
        name,
        address,
        city,
        postCode,
        state: state ?? content.siteContent.site.state,
        country: country ?? content.siteContent.site.country,
        imageUri
    }
    appContext.setLoading = true
    const userAccess = data.users
        .filter((item) => item.userId?.length > 0)
        .map(
            (user) =>
                ({
                    user: user.userId.indexOf("@") >= 0 ? { email: user.userId } : { phone: user.userId },
                    contractRenewalNotice: user.contractRenewalNotice,
                    receiveErrors: user.receiveErrors
                }) as UserAccessData
        )

    dataService.updateSite(context, siteId, site, userAccess, content.siteContent.site.imageUri !== site.imageUri).then(
        () => {
            appContext.setLoading = false
            appContext.modal.add({
                title: "SUCCESS!",
                content: "Your the site has been updated.",
                onHide: () => navigator.navigateTo(Page.Dashboard)
            })
        },
        (error) => {
            appContext.setLoading = false
            appContext.processError(error, "Update Error", "Sorry, the site could not be updated.")
        }
    )
}

function remove(appContext: AppContext, navigator: AuthenticatedNavigatorContext, siteId: string) {
    const context = new DataServiceContext(appContext.session)
    const dataService = ServiceProvider.dataService

    appContext.modal.add({
        title: "Delete Site",
        content: "Are you sure you want to delete this site?",
        buttons: [
            {
                key: "delete",
                content: "OK",
                className: "secondary",
                callback: (key, dialog) => {
                    appContext.modal.close(dialog)
                    appContext.setLoading = true
                    dataService.removeSite(context, siteId).then(
                        () => {
                            navigator.navigateTo(Page.Dashboard)
                            appContext.setLoading = false
                        },
                        (error) => {
                            appContext.processError(error, "Delete Site", "The site could not be deleted.")
                            appContext.setLoading = false
                        }
                    )
                }
            }
        ]
    })
}

function SiteEditorFormContent(props: { content: Content }) {
    const navigator = useAuthenticatedNavigator()
    const appContext = useAppContext()
    const formContext = useContext(SiteEditorFormContext)
    const [userState, setUserState] = useState<UserFormState>(() => getDefaultUserFormState(props.content.userContent))
    const renderedFields = renderFormInputs(formContext, [
        ...siteFields,
        ...createUserFormFields<FormValues>(
            undefined,
            "users",
            userState,
            setUserState,
            formContext as any, // FIXME: Type buster
            props.content?.userContent
        )
    ]) as RenderedFields

    const deleteSite = () => remove(appContext, navigator, props.content.siteId)

    return (
        <>
            <div className="columns">
                <SiteDetailFormFragment
                    formController={formContext as any} // FIXME: Type buster
                    renderedFields={renderedFields}
                />
                <SiteUserFormFragment
                    formController={formContext as any} // FIXME: Type buster
                    renderedFields={renderedFields}
                    setFormState={setUserState}
                    formState={userState}
                />
            </div>
            <div className="button-panel">
                <button type="button" name="delete" className="secondary" children={"Delete"} onClick={deleteSite} />
                <BackButton />
                <SubmitButton
                    context={SiteEditorFormContext}
                    wrapped={false}
                    submit={{ name: "submit", title: "Save" }}
                />
            </div>
        </>
    )
}

export type EditorProps = { siteId: string; siteName: string }
type Content = { siteId: string; siteContent: SiteContent; userContent: UserContent; state: 0 | 1 | 2 }

export default function Editor(props: EditorProps) {
    const formName = "site-editor"
    const navigator = useAuthenticatedNavigator()
    const appContext = useAppContext()
    const dataContext = React.useMemo(() => new DataServiceContext(appContext.session), [appContext])

    const [content, setContent] = React.useState<Content>({
        siteId: props.siteId,
        siteContent: undefined,
        userContent: undefined,
        state: 0
    })

    if (content.state === 0) {
        content.state = 1 // Don't want an official state update here

        Promise.all([loadSiteContent(dataContext, props.siteId), loadUserContent(dataContext, props.siteId)]).then(
            ([siteContent, userContent]: [SiteContent, UserContent]) =>
                setContent({ siteId: props.siteId, siteContent, userContent, state: 2 })
        )
    }

    if (content.state === 2) {
        const onsubmit = (data: FormValues) => submit(appContext, navigator, content, data)

        const defaultValues: FormValues = {
            ...convertSiteContentToSiteFormValues(content.siteContent),
            users: convertUserContentToUserFormValues(content.userContent)
        }

        return (
            <div id={formName + "-form-container"} className="form-container content-site-editor">
                <Form context={SiteEditorFormContext} defaultValues={defaultValues} name={formName} onSubmit={onsubmit}>
                    <SiteEditorFormContent content={content} />
                </Form>
            </div>
        )
    } else {
        return (
            <div className="loading">
                <FullScreenLoader />
            </div>
        )
    }
}
