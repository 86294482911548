import React, { ReactNode, useContext, useState } from "react"
import { AppContext, useAppContext } from "../../../App"
import Site from "../../../model/Site"
import User, { toUserId } from "../../../model/User"
import SiteUserAccess, { SiteAccessData } from "../../../model/SiteUserAccess"
import ServiceProvider from "../../../service/ServiceProvider"
import { DataServiceContext } from "../../../service/data/DataService"
import { createFormContext, Form, FormController, renderFormInputs } from "../../../component/Form"
import { BaseDialog, ModalContext } from "../../../component/Modal"
import {
    createFormFields as createUserFormFields,
    loadContent as loadUserContent,
    convertContentToFormValues as convertUserContentToUserFormValues,
    Content as UserContent,
    FormValues as UserFormValues,
    RenderedFields as UserRenderedFields,
    SiteUserFormFragment,
    FormState as UserFormState,
    getDefaultFormState as getDefaultUserFormState
} from "./SiteUserFormFragment"
import FullScreenLoader from "../../../component/Loading"

type RenderedFields = UserRenderedFields

type FormValues = UserFormValues & {
    submit: string
}

function submit(props: ShareProps, appContext: AppContext, sites: Site[], data: FormValues) {
    try {
        appContext.setLoading = true
        Promise.all(
            data.users
                .filter((user) => user.userId !== "")
                .map((user) =>
                    ServiceProvider.dataService.linkUserToSites(
                        new DataServiceContext(appContext.session),
                        user.userId.indexOf("@") > -1 ? { email: user.userId } : { phone: user.userId },
                        sites.map((site: Site) => ({
                            active: true,
                            receiveErrors: user.receiveErrors,
                            contractRenewalNotice: user.contractRenewalNotice,
                            site: site
                        }))
                    )
                )
        ).then(
            (result) => {
                appContext.setLoading = false
                props.onSave([].concat(...result))
            },
            (e) => {
                appContext.setLoading = false
                props.onError(e, "Could not link user(s) to site")
            }
        )
    } catch (e) {
        appContext.setLoading = false
        props.onError(e, "An error occurred while updating the user list")
    }
}

type CombinedUserResult = {
    userStatus?: Map<string, { notifications: number; records: { siteAccess: SiteAccessData; user: string | User }[] }>
    siteStatus?: Map<string, { notifications: number; records: { siteAccess: SiteAccessData; user: string | User }[] }>
    userContent?: UserContent
    notificationLimit: number
}

function combineUsers(content: Content): CombinedUserResult {
    const siteCount = content.sites.length
    let notificationLimit = 6

    if (siteCount == 1) {
        return { userContent: content.siteUsers.get(content.sites[0].id), notificationLimit }
    } else if (siteCount > 1) {
        const users = new Map<
            string,
            { notifications: number; records: { siteAccess: SiteAccessData; user: string | User }[] }
        >()
        const sites = new Map<
            string,
            { notifications: number; records: { siteAccess: SiteAccessData; user: string | User }[] }
        >()

        for (let i = 0; i < siteCount; ++i) {
            const siteId = content.sites[i].id
            const userAccess = content.siteUsers.get(content.sites[i].id).userAccess
            const siteStatus = { notifications: 0, records: userAccess }

            sites.set(siteId, siteStatus)

            for (let j = userAccess.length - 1; j >= 0; --j) {
                const access = userAccess[j]
                const userId = toUserId(access.user)
                const hasNotification = access.siteAccess.receiveErrors || access.siteAccess.contractRenewalNotice

                if (userId !== undefined && userId !== "") {
                    if (hasNotification) siteStatus.notifications++

                    const existing = users.get(userId)
                    if (existing) {
                        if (hasNotification) existing.notifications++
                        existing.records.push(access)
                    } else {
                        users.set(userId, { notifications: hasNotification ? 1 : 0, records: [access] })
                    }
                }
            }
        }

        const records: { siteAccess: SiteAccessData; user: string | User }[] = []

        for (const user of users.values()) {
            if (user.records.length !== siteCount) {
                // Exclude user if they're not all present
                // Also, reduce the notification limit if one of the sites has a notification enabled for this user
                if (user.notifications > 0) notificationLimit--
            } else if (user.notifications === 0) {
                // If some of the records have notifications, we force them off for this dialog
                records.push({
                    user: user.records[0].user,
                    siteAccess: { site: undefined, active: true, receiveErrors: false, contractRenewalNotice: false }
                })
            } else {
                let receiveErrors = true
                let contractRenewalNotice = true

                for (let i = user.records.length - 1; i >= 0; --i) {
                    const record = user.records[i]
                    if (!record.siteAccess.receiveErrors) receiveErrors = false
                    if (!record.siteAccess.contractRenewalNotice) contractRenewalNotice = false
                }

                records.push({
                    user: user.records[0].user,
                    siteAccess: { site: undefined, active: true, receiveErrors, contractRenewalNotice }
                })
            }
        }

        if (notificationLimit < 0) notificationLimit = 0
        return { userStatus: users, siteStatus: sites, userContent: { userAccess: records }, notificationLimit }
    } else {
        // Not the best place to catch this, just return empty result set
        return { notificationLimit }
    }
}

const SiteShareFormContext = createFormContext<FormValues>()

export type ShareProps = {
    siteIds: string[]
    controller: React.MutableRefObject<SiteShareController>
    onSave: (result: SiteUserAccess[]) => void
    onError: (error: unknown, content: string) => void
}

type Content = {
    sites: Site[] | undefined
    siteUsers: Map<string, UserContent>
    state: 0 | 1 | 2
}

export class SiteShareController {
    private readonly _appContext: AppContext
    private readonly _formControllerRef: React.RefObject<FormController<FormValues>>
    private readonly _props: ShareProps
    private readonly _content: Content

    constructor(
        appContext: AppContext,
        props: ShareProps,
        content: Content,
        formControllerRef: React.RefObject<FormController<FormValues>>
    ) {
        this._appContext = appContext
        this._props = props
        this._content = content
        this._formControllerRef = formControllerRef
    }

    public get isLoaded() {
        return this._content.state === 2
    }

    public save() {
        const form = this._formControllerRef.current

        if (!this.isLoaded) {
            const error = new Error("Cannot submit SiteShare, data has not been loaded")
            this._props.onError(error, "Could not save user list")
            return
        }

        if (!form) {
            const error = new Error("Cannot submit SiteShare, form is not ready")
            this._props.onError(error, "Could not save user list")
            return
        }

        submit(this._props, this._appContext, this._content.sites, form.getValues())
    }
}

export class SiteShareDialog extends BaseDialog {
    // State variables
    private appContext: AppContext
    private modal: ModalContext
    private active = false
    private ref: React.MutableRefObject<SiteShareController> = { current: undefined }

    constructor(appContext: AppContext, modal: ModalContext, sites: string[]) {
        super()

        this.appContext = appContext
        this.modal = modal
        this.title = "Share Site"
        this.onShow = () => (this.active = true)
        this.onHide = () => (this.active = false)
        this.content = () => (
            <SiteShare
                controller={this.ref}
                siteIds={sites}
                onSave={() => modal.close(this)}
                onError={(e: unknown, content: ReactNode) =>
                    this.appContext.processError(e, "Site Share Error", content)
                }
            />
        )
        this.close = { enabled: true, content: "Cancel", className: "secondary" }
        this.buttons = [
            {
                key: "submit",
                className: "primary",
                content: "Share",
                // callback: () => this.active && this.ref.current?.save()
                callback: async () => {
                 appContext.setLoading = true

                    try {
                    const response = this.active && this.ref.current?.save();

                    appContext.setLoading = false
                        appContext.modal.add({
                            title: "SUCCESS!",
                            content: "Site Shared Successfully.",
                            close: {
                                enabled: true
                            }
                        });
                    } catch (error) {
                    appContext.setLoading = false
                        console.error("Error:", error);
                    }
                }
            }
        ]
    }
}

export function SiteShare(props: ShareProps) {
    const formName = "site-share"
    const appContext = useAppContext()
    const formControllerRef = React.useRef<FormController<FormValues>>()
    const [content, setContent] = React.useState<Content>({ sites: undefined, siteUsers: undefined, state: 0 })

    if (content.state === 0) {
        const context = new DataServiceContext(appContext.session)
        const dataService = ServiceProvider.dataService
        const newContent: Content = { sites: undefined, siteUsers: new Map(), state: 2 }

        Promise.all([
            dataService.getSites(context, props.siteIds).then((sites) => (newContent.sites = sites)),
            ...props.siteIds.map(
                (siteId) =>
                    loadUserContent(context, siteId).then((userContent) =>
                        newContent.siteUsers.set(siteId, userContent)
                )
            )
        ]).then(
            () => setContent(newContent),
            (e) => {
                appContext.processError(e, "Site Share Error", "An error occurred while receiving the user list")
            }
        )

        content.state = 1
    }

    if (props.controller) {
        props.controller.current = new SiteShareController(appContext, props, content, formControllerRef)
    }

    const combinedUsers = content.state === 2 ? combineUsers(content) : undefined

    return (
        <div id={formName + "-form-container"} className="form-container content-site-share">
            {content.state === 2 ? (
                <Form
                    context={SiteShareFormContext}
                    controller={formControllerRef}
                    name={formName}
                    defaultValues={{ users: convertUserContentToUserFormValues(combinedUsers.userContent) }}
                    onSubmit={(data: FormValues) => submit(props, appContext, content.sites, data)}
                >
                    <SiteShareFormContent sites={content.sites} combinedUsers={combinedUsers} />
                </Form>
            ) : (
                <FullScreenLoader />
            )}
        </div>
    )
}

function SiteShareFormContent({ combinedUsers, sites }: { combinedUsers: CombinedUserResult; sites: Site[] }) {
    const formContext = useContext(SiteShareFormContext)
    const [userState, setUserState] = useState<UserFormState>(() => getDefaultUserFormState(combinedUsers.userContent))

    const renderedSites = sites.map((site) => {
        const siteStatus = combinedUsers.siteStatus?.get(site.id)
        const siteText = siteStatus
            ? ` (Users: ${siteStatus.records.length}; Notifications: ${siteStatus.notifications})`
            : ""
        return (
            <li key={"site#" + site.id}>
                {site.name}
                {siteText}
            </li>
        )
    })

    const renderedFields: RenderedFields = renderFormInputs(
        formContext,
        createUserFormFields<FormValues>(
            undefined,
            "users",
            userState,
            setUserState,
            formContext as any, // FIXME: Remove type buster
            combinedUsers.userContent,
            combinedUsers.notificationLimit
        )
    ) as RenderedFields

    return (
        <div className="screen screen-site-share">
            <section className="section-sites">
                <h3>Share the selected sites to these users</h3>
                <ul children={renderedSites} />
            </section>
            <SiteUserFormFragment
                renderedFields={renderedFields}
                formController={formContext as any} // FIXME: Remove type buster
                formState={userState}
                setFormState={setUserState}
                notificationLimit={combinedUsers.notificationLimit}
            />
        </div>
    )
}
